import { render, staticRenderFns } from "./internalBooking.vue?vue&type=template&id=6bce8367&scoped=true"
import script from "./internalBooking.vue?vue&type=script&lang=js"
export * from "./internalBooking.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bce8367",
  null
  
)

export default component.exports
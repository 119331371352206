<template>
  <!-- 
  issus:
   -->
  <div>

    <!-- Start notification -->
    <successNotification v-if="this.showSuccessNotification == true"></successNotification>
    <failNotification v-if="this.showFailNotification == true"></failNotification>
    <!-- End notification -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6  px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">
          <h3 class="text-3xl font-bold mb-2">حجز اجتماع جديد خارجي</h3>

          <div class="border rounded-lg">
            <div class=" w-fit mx-auto my-16 space-y-10">

              <!-- FIRST ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="entityType">نوع الجهة</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="entityType" id="entityType" v-model="entityType">
                      <option value="خارجي">خارجي</option>
                    </select>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="entityName">اسم الجهة</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="entityName" id="entityName" placeholder="ادخل اسم الجهة"
                      v-model="entityName" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('entityName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityName == '' && errors.first('entityName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF FIRST ROW OF THE FORM -->

              <!-- SECOND ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="reservationHolderName">اسم صاحب الحجز</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="reservationHolderName" id="reservationHolderName"
                      placeholder="اسم صاحب الحجز" v-model="reservationHolderName" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('reservationHolderName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityActivity == '' && errors.first('reservationHolderName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="entityActivity">نشاط الجهة</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="entityActivity" id="entityActivity"
                      placeholder="نشاط الجهة" v-model="entityActivity" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('entityActivity') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityActivity == '' && errors.first('entityActivity') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF SECOND ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="phoneNumber">رقم الجوال</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild text-right" dir="ltr" name="phoneNumber" id="phoneNumber"
                      placeholder="05" v-model="phoneNumber" v-validate="'required|numeric|min:10|max:10'">
                  </div>
                  <p class="error">{{ errors.first('phoneNumber') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="phoneNumber == '' && errors.first('phoneNumber') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
                  <div @change="available_hours(); timeRange()" class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="meetingRoom" id="meetingRoom" v-model="meetingRoom">
                      <option v-for="(room, index) in meetingRooms" :key="index" :value="room">{{ room }}</option>
                    </select>
                  </div>
                </div>

              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <!-- 4 ROW OF THE FORM -->
              <div class="flex justify-start gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
                  <div>
                    <input @change="checkUnavailableDates" class="inputFild" type="date" name="meetingDate"
                      id="meetingDate" placeholder="حدد وقت نهاية العمل كل يوم" v-model="meetingDate" :min="minDate"
                      v-validate="'required'">
                  </div>
                  <p class="error">{{ errors.first('meetingDate') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meetingDate == '' && errors.first('meetingDate') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class=" space-y-2">
                  <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
                  <div class="w-[360px]">
                    <select :disabled="disableLastInputs" @change="deleteStartTime" @click="removePastTimes"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="meeting_duration" id="meeting_duration" v-model="meeting_duration" v-validate="'required'">
                      <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('meeting_duration') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meeting_duration == '' && errors.first('meeting_duration') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF 4 ROW OF THE FORM -->

              <!-- 5 ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
                  <div class="w-[360px]">
                    <select @focus="filteredStartTimes" @change="filteredEndTimes" :disabled="disableIfNoDuration"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="startTime"
                      id="startTime" v-model="startTime" v-validate="'required'">
                      <option v-for="(time, index) in filteredTimes" :key="index" :value="time"
                        style="direction: ltr; text-align: right;">{{ time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('startTime') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="startTime == '' && errors.first('startTime') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

              </div>
              <!-- END OF 5 ROW OF THE FORM -->

              <!-- 6 ROW OF THE FORM -->
              <div class="flex justify-center gap-4">

                <div class=" space-y-2">
                  <p v-if="this.startTime && this.endTime">بداية وقت الاجتماع الساعة {{ this.startTime }} وينتهي
                    الساعة {{ this.endTime }}</p>
                </div>

              </div>
              <!-- END OF 6 ROW OF THE FORM -->

              <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                    </div>
                </div>
              </div>

              <!-- Start Button -->
              <div class=" text-center">
                <button @click="submitData"
                  class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">إضافة</button>
              </div>
              <!-- End Button -->
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import menuComponent from '@/components/menu-booking.vue'
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import filter from '@/mixins/filter.js'
import { supabase } from '../../../supabase';

export default {
  name: 'internalBooking',
  components: {
    menuComponent,
    headerComponent,
    successNotification,
    failNotification
  },
  data() {
    return {
      identificationNumber: '',

      entityType: 'خارجي',
      entityName: '',
      reservationHolderName: '',

      entityActivity: '',
      phoneNumber: '',

      meetingRooms: [],
      meetingRoom: '',

      timeArray: [],
      filteredTimes: [],

      meetingDate: '',
      unavailableDates: [],

      duration: [],
      meeting_duration: '',

      startTime: '',
      endTime: '',

      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,

      isLoading: false // icon of loading 
    }
  },
  methods: {
    // Function to submit meeting data
    async submitData() {
  // Check if all required fields are filled
  if (this.entityType && this.entityName && this.entityActivity && this.phoneNumber && this.meetingRoom
    && this.meetingDate && this.meeting_duration && this.startTime && this.endTime) {

    // Set isLoading to true before starting the data submission process
    this.isLoading = true;

    try {
      // Insert a new Meeting record in the 'Meeting' table
      const { data } = await supabase
        .from('Meeting')
        .insert([{ reservationHolderName: this.reservationHolderName, entityName: this.entityName, entityType: this.entityType, entityActivity: this.entityActivity, phoneNumber: this.phoneNumber, meetingRoom: this.meetingRoom, meetingDate: this.meetingDate, meeting_duration: this.meeting_duration, startTime: this.startTime, endTime: this.endTime, meetingStatus: 'معلق' },])
        .select();

      // Show the success notification after insertion is successful
      this.showSuccessNotification = true;

      // Hide the notification after 3 seconds
      setTimeout(async () => {
        this.showSuccessNotification = false;
        // Assign the identification number of the newly inserted meeting record
        this.identificationNumber = data[0].identificationNumber;
        // Insert a notification to inform about the successful submission of meeting data
        this.insertNotification();
        // Redirect to the meeting management page after hiding the notification
        this.$router.push('/meetingManagementPage');
      }, 1700);

    } catch (error) {
      console.error('Error inserting data:', error.message);

      // Show the fail notification if insertion fails
      this.showFailNotification = true;

      // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
      }, 1700);

    } finally {
      // Set isLoading to false after the data submission process is completed
      this.isLoading = false;
    }

  } else {
    // Show the fail notification if any field is empty or there are validation errors
    this.showFailNotification = true;

    // Set a timeout function to execute after 1700 milliseconds. This function will hide the fail notification and show the validation error notification.
    setTimeout(() => {
      this.showFailNotification = false;
      this.showValidationError = true;
    }, 1700);
    return; // Stop the function execution here
  }
},

    // Function to asynchronously insert a notification into the database
    async insertNotification() {

      // Prepare the notification object
      const notification = {
        by: sessionStorage.getItem('userName'), // Get the sender's username from sessionStorage
        notificationType: 'newExMeeting', // Set the notification type to 'newExMeeting'
        identificationNumber: this.identificationNumber, // Include the identification number associated with the meeting
        readed_by: [], // Initialize an empty array for users who have read the notification
      };

      // Send the notification to the Supabase notification table
      await supabase
        .from('notification')
        .insert([notification])
        .then(result => {
          // Check if there is an error in the result
          if (result.error) {
            console.error('Failed to send notification:', result.error); // Log the error if there is one
          }
        }).catch(error => {
          console.error('Error sending notification:', error);  // Log any error that occurred during the process
        });
        
    },
  },
  computed: {
    // Function to disable inputs based on certain conditions
    disableLastInputs() {
      if (this.entityType && this.entityName && this.entityActivity && this.phoneNumber && this.meetingRoom && this.meetingDate) {
        return false;
      } else {
        return true;
      }
    },
    // Function to disable inputs if meeting duration is not set
    disableIfNoStartTime() {
      if (this.startTime) {
        return false;
      } else {
        return true;
      }
    },
  },
  mixins: [filter],
  async mounted() {
    // Retrieve the meeting room names from the 'Hall' table in the database
    let { data: Hall } = await supabase
      .from('Hall')
      .select('hallName, hallStatus')
    this.meetingRooms.push(...Hall.filter((e) => e.hallStatus === 'فعال').map((e) => e.hallName))
  },
}
</script>

<style scoped></style>